/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable prefer-const */
/* eslint-disable import/no-cycle */
/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  FilterModel,
  GridType,
  HoverType,
  IFilterConfigDTO,
  IFilterSelectionValue,
  IGalleryControllerConfig,
  IGetInitialData,
  ImageModeId,
  ImagePlacements,
  ImagePositions,
  ImageRatioId,
  IProduct,
  IPropsInjectedByViewerScript,
  ITextsMap,
  LoadMoreType,
  MobileFiltersPanelState,
  PaginationType,
  PaginationTypeName,
  ProductsManifest,
  VeloInputs,
} from '../types/galleryTypes';
import {
  AddToCartActionStatus,
  BiEventParam,
  CategoryListStrategy,
  DEFAULT_COLS,
  DEFAULT_MOBILE_PRODUCTS_COUNT,
  DEFAULT_ROWS,
  Experiments,
  FedopsInteraction,
  GallerySlotIds,
  STORES_CATEGORY_SEO,
  STORES_GALLERY_SEO,
  translationPath,
} from '../constants';
import {
  AddToCartActionOption,
  APP_DEFINITION_ID,
  PageMap,
  StoresWidgetID,
} from '@wix/wixstores-client-core/dist/es/src/constants';
import {FilterConfigsService} from '../services/filters/FilterConfigsService';
import {FiltersService} from '../services/filters/FiltersService';
import {getReleaseFromBaseUrl} from '@wix/native-components-infra/dist/es/src/sentryUtils/getReleaseFromBaseUrl';
import {MultilingualService} from '@wix/wixstores-client-storefront-sdk/dist/es/src/services/MultilingualService/MultilingualService';
import {ProductsService} from '../services/ProductsService';
import {DefaultQueryParamKeys, GalleryQueryParamsService} from '../services/GalleryQueryParamsService';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {SortService} from '../services/sort/SortService';
import {getTranslations} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/utils';
import {getInitialProductsCountToFetch} from './utils';
import {AddToCartService} from '@wix/wixstores-client-storefront-sdk/dist/es/src/services/AddToCartService/AddToCartService';
import {actualPrice, isPreOrder} from '@wix/wixstores-client-core/dist/es/src/productOptions/productUtils';
import type {ISentryErrorBoundaryPropsInjectedByViewerScript} from '@wix/native-components-infra/dist/es/src/HOC/sentryErrorBoundary/sentryErrorBoundary';
import {CustomUrlApi} from '@wix/wixstores-client-storefront-sdk/dist/es/src/utils/CustomUrl/CustomUrlApi';
import {BaseGalleryStore} from './BaseGalleryStore';
import _ from 'lodash';
import {ProductPriceRange} from '@wix/wixstores-client-storefront-sdk/dist/es/src/services/ProductPriceRange/ProductPriceRange';
import {unitsTranslations} from '../common/components/ProductItem/ProductPrice/unitsTranslations';
import {ProductPriceBreakdown} from '@wix/wixstores-client-storefront-sdk/dist/es/src/services/ProductPriceBreakdown/ProductPriceBreakdown';
import {
  categoryPageBreadcrumbClicked,
  categoryPageCategoryTreeClicked,
  categoryPageHeroSectionReadLessClicked,
  categoryPageHeroSectionReadMoreClicked,
  clickLoadMoreInGallerySf,
  clickOnProductBoxSf,
  clickToChangeGalleryFiltersSf,
  exposureEventForTests,
  galleryClickApplyFilter,
  galleryClickClearAllFilters,
  galleryClickFilter,
  galleryClickSortBy,
  sortGallerySf,
} from '@wix/bi-logger-ec-sf/v2';
import {viewGallerySf} from '@wix/bi-logger-stores-data/v2';
import {ICategory} from '../types/category';
import {GetCategoryInitialDataQuery, GetDataQuery} from '../graphql/queries-schema';
import {ILink, RouterPrefix} from '@wix/wixstores-client-core';
import {galleryColumnsDefaultValue, galleryRowsDefaultValue} from '../styleParams/baseStylesParams';
import gridGalleryStylesParams from '../components/GridGallery/stylesParams';
import categoryStylesParams from '../components/CategoryPage/stylesParams';
import searchResultsPageStylesParams from '../components/SearchResultsPageGallery/stylesParams';
import searchModalPageStylesParams from '../components/SearchModalGallery/stylesParams';
import {BreadcrumbsItem} from 'wix-ui-tpa';
import {CategoriesData, CategoriesService} from '../services/CategoriesService';
import {createSlotVeloAPIFactory} from '@wix/widget-plugins-ooi/velo';
import type {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import {ExternalDataSourceConfig} from '../types/externalDataSourceConfig';
import {ProductItemsService} from '../services/ProductItemsService';
import {ISorting, ISortingOption} from '../types/sorting';
import {FiltersAnnouncerService} from '../services/filters/FiltersAnnouncerService';
import {getProductPageSectionUrl} from '../common/url/productPageUrl';
import {ReportError} from './GalleryController';
import {getConversionRate, ConversionRateResponse} from '../api/getConversionRate';

export class GalleryStore extends BaseGalleryStore {
  private readonly panoramaClient: ControllerFlowAPI['panoramaClient'];
  private addedToCartStatus: {[p: string]: AddToCartActionStatus} = {};
  private currentPage: number = 1;
  private scrollToProduct: string;
  private filterConfigsService: FilterConfigsService;
  private filtersService: FiltersService;
  private isFedopsReport: boolean = true;
  private multilingualService: MultilingualService;
  private categoriesService: CategoriesService;
  private productPageSectionUrl: string;
  private readonly productsPerPage: number;
  private readonly queryParamsService: GalleryQueryParamsService;
  private readonly filterAnnouncerService: FiltersAnnouncerService;
  private readonly fedopsLogger;
  private readonly sortService: SortService;
  private translations;
  private readonly customUrlApi: CustomUrlApi;
  private isUrlWithOverrides: boolean = false;
  private mainCollectionId: string = null;
  private currentCategory: ICategory;
  private homepageUrl: string;
  private breadcrumbsItems: any[] = [];
  private taxonomicalBreadcrumbsItems: any[] = [];
  private isCategoryVisible: boolean = true;
  private galleryRows: number;
  private externalDataSourceConfig: ExternalDataSourceConfig;

  // eslint-disable-next-line @typescript-eslint/tslint/config
  constructor(
    config: IGalleryControllerConfig,
    updateComponent: (props: Partial<IPropsInjectedByViewerScript>) => void,
    siteStore: SiteStore,
    compId: string,
    type: string,
    flowAPI: ControllerFlowAPI,
    reportError: ReportError,
    slotAPIFactory: ReturnType<typeof createSlotVeloAPIFactory>,
    veloInputs?: VeloInputs
  ) {
    super(config, siteStore, updateComponent, type, compId, flowAPI, reportError, slotAPIFactory, veloInputs);
    this.panoramaClient = this.flowAPI.panoramaClient;
    const shouldUseNewApplyFilterQueryParams =
      this.type === StoresWidgetID.SEARCH_RESULTS_GALLERY ||
      this.siteStore.experiments.enabled(Experiments.UseGalleryNewApplyFilterQueryParams);
    this.queryParamsService = new GalleryQueryParamsService({
      siteStore: this.siteStore,
      isMobile: this.siteStore.isMobile(),
      isCategoryPage: this.isCategoryPage,
      usingMultiCollectionFilters: this.usingExtendedGridGalleryWidget,
      shouldUseNewApplyFilterQueryParams,
    });

    const fedopsLoggerFactory = this.siteStore.platformServices.fedOpsLoggerFactory;
    this.fedopsLogger = fedopsLoggerFactory.getLoggerForWidget({
      appId: APP_DEFINITION_ID,
      widgetId: this.type,
    });

    this.sortService = new SortService(this.type as StoresWidgetID, this.styles);

    this.addToCartService = new AddToCartService(this.siteStore, this.publicData);
    this.setGalleryRows();

    this.productItemsService = new ProductItemsService(this.updateComponent);

    this.productsService = new ProductsService(
      this.siteStore,
      this.biType,
      this.shouldShowProductOptions,
      this.shouldRenderPriceRange,
      this.fedopsLogger,
      this.type === StoresWidgetID.GALLERY_PAGE,
      this.panoramaClient,
      this.flowAPI.controllerConfig.wixCodeApi,
      this.productItemsService
    );
    this.productsPerPage = this.productsService.getProductPerPage();
    this.customUrlApi = new CustomUrlApi(this.siteStore.location.buildCustomizedUrl);
    this.filterAnnouncerService = new FiltersAnnouncerService({updateComponent});
  }

  private get isCategoryPage() {
    return this.type === StoresWidgetID.CATEGORY_PAGE;
  }

  private get usingExtendedGridGalleryWidget() {
    return [
      StoresWidgetID.CATEGORY_PAGE,
      StoresWidgetID.SEARCH_MODAL_GALLERY,
      StoresWidgetID.SEARCH_RESULTS_GALLERY,
    ].includes(this.type as StoresWidgetID);
  }

  public getDataForSEOCarousel(shouldSendSubcategoriesSeoData: boolean) {
    if (!shouldSendSubcategoriesSeoData) {
      return [];
    }
    return this.productsService.products?.slice(0, 5).map((product, index) => {
      return {
        position: index + 1,
        name: product.name,
        price: product.price,
        url: this.getProductPageUrl(product.urlPart),
        image: product.media[0]?.fullUrl,
        currency: this.siteStore.currency,
      };
    });
  }

  private renderCategorySEOTags() {
    const {prevUrl, nextUrl} = this.nextPrevLinksMapped();
    const categoryUrl = this.queryParamsService.getUrl();
    const itemType = STORES_CATEGORY_SEO;
    const shouldSendSubcategoriesSeoData = this.siteStore.experiments.enabled(Experiments.SendSubcategoriesSeoDataSF);

    const categorySeoData = this.categoriesService.getCategorySeoData(
      this.currentCategory.id,
      this.currentCategory.parentCategoryId,
      this.currentCategory.breadcrumbs
    );

    let seoBreadcrumbs: {position: number; name: string; item: string}[] = [
      // eslint-disable-next-line sonarjs/no-duplicate-string
      {position: 1, name: this.translations['gallery.breadcrumbs.firstItem'], item: this.siteStore.location.baseUrl},
    ];
    categorySeoData.breadcrumbs && seoBreadcrumbs.push(...categorySeoData.breadcrumbs);
    seoBreadcrumbs.push({
      position: seoBreadcrumbs.length + 1,
      name: this.currentCategory.name,
      item: `${this.siteStore.location.baseUrl}/${RouterPrefix.CATEGORY}/${this.currentCategory.slug}`,
    });

    const products = this.getDataForSEOCarousel(shouldSendSubcategoriesSeoData);

    const itemData = {
      products,
      category: {
        categoryUrl,
        categoryParents: shouldSendSubcategoriesSeoData ? categorySeoData.categoryParentNames : undefined,
        categoryDirectParent: shouldSendSubcategoriesSeoData ? categorySeoData.categoryDirectParentName : undefined,
        categorySlug: this.currentCategory.slug,
        categoryName: this.currentCategory.name,
        categoryDesc: this.currentCategory.description,
        numberOfItems: this.productsService.totalCount,
        breadcrumbs: shouldSendSubcategoriesSeoData ? seoBreadcrumbs : undefined,
        image: {
          imageUrl: this.currentCategory.media?.fullUrl,
          imageWidth: this.currentCategory.media?.width,
          imageHeight: this.currentCategory.media?.height,
          hasImage: !!this.currentCategory.media,
          imageAlt: this.currentCategory.name,
        },
        pagination: {
          prevUrl,
          nextUrl,
          totalPages: this.totalPages,
          currentPage: this.currentPage,
        },
      },
    };
    const seoData = this.currentCategory.seoData && JSON.parse(this.currentCategory.seoData);

    this.siteStore.seo.renderSEOTags({
      itemType,
      itemData,
      seoData,
    });
  }

  private renderGallerySEOTags() {
    const {prevUrl, nextUrl} = this.nextPrevLinksMapped();
    const url = this.queryParamsService.getUrlWithCustomPageParamForSeo(this.currentPage);
    const itemType = STORES_GALLERY_SEO;
    const itemData = {
      shop: {
        url,
      },
      pagination: {
        prevUrl,
        nextUrl,
        totalPages: this.totalPages,
        currentPage: this.currentPage,
      },
      items: {
        numberOfItems: this.productsService.totalCount,
      },
    };

    this.siteStore.seo.renderSEOTags({
      itemType,
      itemData,
    });
  }

  private isStyleParamModified(styleParamName: string) {
    return !!this.config.style.styleParams?.numbers?.[styleParamName];
  }

  private get isGalleryRowsAndColsWereModified() {
    return this.isStyleParamModified('galleryRows') && this.isStyleParamModified('galleryColumns');
  }

  private get isFixedProductsCountModified(): boolean {
    return this.isStyleParamModified('gallery_fixedGridProductsCount');
  }

  private get shouldRenderPriceRange(): boolean {
    return new ProductPriceRange(this.siteStore).shouldShowPriceRange();
  }

  private get sentryErrorBoundaryProps(): ISentryErrorBoundaryPropsInjectedByViewerScript {
    return {
      ravenUserContextOverrides: {id: this.siteStore.storeId, uuid: this.siteStore.uuid},
      sentryRelease: getReleaseFromBaseUrl(this.siteStore.baseUrls.galleryBaseUrl, {
        artifactName: true,
      }),
    };
  }

  private get shouldShowImageCarousel(): boolean {
    const hoverType = this.getStyleParamByDevice(
      this.styles['mobile:gallery_imageEffect'],
      this.styles.gallery_hoverType
    );

    return (
      this.siteStore.experiments.enabled(Experiments.GalleryProductItemCarouselHover) &&
      hoverType.value === HoverType.Carousel
    );
  }

  protected get shouldShowProductOptions(): boolean {
    const styleParam = this.siteStore.isMobile()
      ? this.stylesParams['mobile:gallery_showProductOptions']
      : this.stylesParams.gallery_showProductOptionsButton;

    return this.isTrueInAnyBreakpoint(styleParam);
  }

  public setVeloInputs(veloInputs: VeloInputs) {
    this.veloInputs = {
      ...this.veloInputs,
      ...veloInputs,
    };
  }

  public setExternalDataSourceConfig(externalDataSourceCallbacks: ExternalDataSourceConfig) {
    this.externalDataSourceConfig = externalDataSourceCallbacks;
    this.productsService.setExternalDataSourceGetProducts(externalDataSourceCallbacks.getProducts);
  }

  private getTranslation() {
    return this.translations
      ? Promise.resolve(this.translations)
      : getTranslations(translationPath(this.siteStore.baseUrls.galleryBaseUrl, this.siteStore.locale));
  }

  private setGallerySlotsProps() {
    if (this.siteStore.experiments.enabled(Experiments.RenderSlotsInGallery)) {
      const gallerySlotIds = Object.values(GallerySlotIds);
      for (const slotId of gallerySlotIds) {
        try {
          const slot = this.slotAPIFactory.getSlotAPI(slotId);
          slot.categoryId = this.mainCollectionId;
        } catch (e) {
          /* istanbul ignore next: nothing to test  */
          this.reportError(e);
        }
      }
    }
  }

  public async setInitialState(): Promise<any> {
    const {gallery_gridType, gallery_productsCount} = this.styles;
    const initialProductsCount = getInitialProductsCountToFetch({
      isMobile: this.siteStore.isMobile(),
      isEditor: this.siteStore.isEditorMode(),
      isAutoGrid: gallery_gridType === GridType.AUTO,
      rows: this.galleryRows,
      cols: this.galleryColumns,
      autoGridProductsCount: gallery_productsCount,
      isGalleryRowsAndColsWereModified: this.isGalleryRowsAndColsWereModified,
      isFixedProductsCountModified: this.isFixedProductsCountModified,
      externalProductsPerPage: this.externalProductsPerPage,
    });
    this.productsService.setProductsPerPage(initialProductsCount);
    this.isCategoryPage ? await this.setCategoryInitialState() : await this.setGalleryInitialState();
    this.setGallerySlotsProps();
  }

  private get galleryColumns() {
    /* istanbul ignore next: I think it will never reach DEFAULT_COLS, but i'm afraid to remove */
    return (
      this.styles.galleryColumns ??
      galleryColumnsDefaultValue({
        dimensions: this.config.dimensions,
        isMobile: this.siteStore.isMobile(),
      }) ??
      DEFAULT_COLS
    );
  }

  private setGalleryRows(shouldUpdateFixedProductsCount: boolean = false): void {
    if (this.isFixedProductsCountModified || shouldUpdateFixedProductsCount) {
      const styleParamProductsCount = this.styles.gallery_fixedGridProductsCount;
      this.galleryRows = styleParamProductsCount / this.galleryColumns;
      return;
    }

    this.galleryRows =
      this.styles.galleryRows ??
      galleryRowsDefaultValue({getStyleParamValue: () => this.galleryColumns}) ??
      /* istanbul ignore next: I think it will never reach DEFAULT_ROWS, but i'm afraid to remove */
      DEFAULT_ROWS;
  }

  private get externalProductsPerPage() {
    return this.externalDataSourceConfig?.productsPerPage;
  }

  private get maxProductsPerPage() {
    if (this.externalProductsPerPage) {
      return this.externalProductsPerPage;
    }
    const shouldUseMobileDefaults =
      this.siteStore.isMobile() &&
      !this.isGalleryRowsAndColsWereModified &&
      !this.isFixedProductsCountModified &&
      !this.config.usesCssPerBreakpoint;

    switch (true) {
      case this.shouldUseAutoGridProductsCount:
        return this.styles.gallery_productsCount;
      case shouldUseMobileDefaults:
        return DEFAULT_MOBILE_PRODUCTS_COUNT;
      default:
        return this.galleryColumns * this.galleryRows;
    }
  }

  private get totalPages() {
    return Math.ceil(this.productsService.totalCount / this.maxProductsPerPage);
  }

  private get productsLimit() {
    let limit: number;

    if (this.isAutoGrid()) {
      limit = this.styles.gallery_productsCount;
    }

    if (this.externalProductsPerPage) {
      limit = this.externalProductsPerPage;
    }

    if (this.siteStore.location.query.page) {
      limit = this.getProductsLimitByPageFromQueryParam(this.productsService.getProductPerPage());
    }

    return limit;
  }

  private createMultilingualService(widgetSettings: GetDataQuery['appSettings']['widgetSettings']) {
    return new MultilingualService(this.siteStore, this.publicData.COMPONENT, widgetSettings);
  }

  private createProductPriceBreakdownService() {
    return new ProductPriceBreakdown(this.siteStore, this.translations, {
      excludedPattern: 'gallery.price.tax.excludedParam.label',
      includedKey: 'gallery.price.tax.included.label',
      includedPattern: 'gallery.price.tax.includedParam.label',
      excludedKey: 'gallery.price.tax.excluded.label',
    });
  }

  private createFilterConfigsService(widgetSettings: GetDataQuery['appSettings']['widgetSettings']) {
    return new FilterConfigsService(
      widgetSettings.FILTERS,
      this.publicData.COMPONENT?.FILTERS?.data,
      this.styles,
      this.multilingualService,
      this.translations,
      this.isCategoryPage
    );
  }

  private createFiltersService(currencyRateResponse: ConversionRateResponse) {
    let conversionRate = 1;
    if (this.shouldQueryCurrencyRate) {
      const {value, decimalPlaces} = currencyRateResponse.data?.rate;
      conversionRate = parseFloat(value) / Math.pow(10, decimalPlaces);
    }
    const currencyFormatter = this.flowAPI.getCurrencyFormatter({maximumFractionDigits: 0});

    return new FiltersService({
      siteStore: this.siteStore,
      mainCollectionId: this.mainCollectionId,
      filterConfigsService: this.filterConfigsService,
      allProductsCategoryId: this.productsService.allProductsCategoryId,
      formatCurrency: currencyFormatter,
      currency: this.siteStore.getCurrentCurrency(),
      conversionRate,
      usingMultiCollectionFilters: this.usingExtendedGridGalleryWidget,
      externalDataSourceFiltersApi: this.externalDataSourceConfig?.getFiltersMetadata,
    });
  }

  private async setGalleryInitialState() {
    let data: GetDataQuery, translations, sectionUrl, products, currencyRateResponse: ConversionRateResponse;

    this.currentPage =
      this.type === StoresWidgetID.SEARCH_MODAL_GALLERY ? 1 : this.queryParamsService.getPageQueryParam();
    this.scrollToProduct = this.queryParamsService.getScrollToProductQueryParam();

    const sorting: ISortingOption = this.getSortFromQueryParam();
    if (sorting) {
      this.sortService.setSelectedSort(sorting.id);
      this.productsService.updateSort(sorting);
    }

    const translationsPromise = this.getTranslation();
    const dataPromise = this.productsService.getInitialData(this.getInitialDataOptions(this.productsLimit));
    const sectionUrlPromise = this.siteStore.getSectionUrl(PageMap.PRODUCT);

    const promises = [translationsPromise, dataPromise, sectionUrlPromise];

    const {currency: defaultCurrency} = this.siteStore;
    const currentCurrency = this.siteStore.getCurrentCurrency();
    this.shouldQueryCurrencyRate &&
      promises.push(getConversionRate(this.flowAPI.httpClient, this.reportError, defaultCurrency, currentCurrency));

    [translations, data, sectionUrl, currencyRateResponse] = await Promise.all(promises).catch(this.reportError);

    this.translations = translations;
    this.productPageSectionUrl = getProductPageSectionUrl(
      sectionUrl,
      this.siteStore.location.baseUrl,
      this.siteStore.experiments
    );
    this.mainCollectionId = this.productsService.getMainCollectionId();
    this.isUrlWithOverrides = await this.customUrlApi.init(); // TODO: Why is it in Promise.all on Category and here not?
    this.isCategoryVisible = data.catalog.category.visible;

    products = data.catalog.category.productsWithMetaData.list;

    this.productPriceBreakdown = this.createProductPriceBreakdownService();
    this.multilingualService = this.createMultilingualService(data.appSettings.widgetSettings);
    this.filterConfigsService = this.createFilterConfigsService(data.appSettings.widgetSettings);

    this.filtersService = this.createFiltersService(currencyRateResponse);

    let filterModels: FilterModel[] | [] = [];
    if (this.shouldShowFilters()) {
      filterModels = await this.fetchFilters();

      filterModels = this.updateFiltersFromQueryParams(filterModels);
      this.productsService.updateFilters(this.filtersService.getFiltersDTO());

      if (
        this.siteStore.experiments.enabled(Experiments.DontFetchFilteredProductsWithoutActiveFilters) &&
        this.filtersService.hasSelectedFilters()
      ) {
        products = await this.getFilterProducts();
      }
    }

    if (this.loadMoreType() === LoadMoreType.PAGINATION && this.queryParamsService.getQueryParam('page')) {
      products = await this.loadProductsByPage(this.currentPage);
    }

    let isFirstPage = true;

    if (this.currentPage > 1) {
      isFirstPage = false;
    }

    this.setProductItemSlotsProps(products);

    this.renderGallerySEOTags();

    this.updateComponent({
      ...this.getPropsToInject(products, filterModels),
      isFirstPage,
    });

    if (!this.siteStore.isSSR()) {
      this.productsService.storeNavigation(this.siteStore.siteApis.currentPage.id);
    }

    await this.externalDataSourceConfig?.onGalleryRendered?.();
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  private async setCategoryInitialState() {
    let initialData: GetCategoryInitialDataQuery,
      translations,
      sectionUrl,
      isUrlWithOverrides,
      products,
      homePage: ILink,
      currencyRateResponse: ConversionRateResponse;

    this.currentPage = this.queryParamsService.getPageQueryParam();
    this.scrollToProduct = this.queryParamsService.getScrollToProductQueryParam();
    const routerData = this.siteStore.windowApis.getRouterPublicData<ICategory>();

    const translationPromise = this.getTranslation();
    const categoryInitialDataPromise = this.productsService.getCategoryInitialData(
      this.config.externalId,
      routerData?.id
    );
    const getSectionUrlPromise = this.siteStore.getSectionUrl(PageMap.PRODUCT);
    const getCustomUrlApiPromise = this.customUrlApi.init();
    const getHomepageLinkPromise = this.siteStore.getHomepageLink();

    const promises = [
      translationPromise,
      categoryInitialDataPromise,
      getSectionUrlPromise,
      getCustomUrlApiPromise,
      getHomepageLinkPromise,
    ];

    const {currency: defaultCurrency} = this.siteStore;
    const currentCurrency = this.siteStore.getCurrentCurrency();
    this.shouldQueryCurrencyRate &&
      promises.push(getConversionRate(this.flowAPI.httpClient, this.reportError, defaultCurrency, currentCurrency));

    [translations, {data: initialData}, sectionUrl, isUrlWithOverrides, homePage, currencyRateResponse] =
      await Promise.all(promises).catch(this.reportError);

    this.translations = translations;
    this.isUrlWithOverrides = isUrlWithOverrides;
    this.productPageSectionUrl = getProductPageSectionUrl(
      sectionUrl,
      this.siteStore.location.baseUrl,
      this.siteStore.experiments
    );
    this.homepageUrl = homePage.url;

    this.multilingualService = this.createMultilingualService(initialData.appSettings.widgetSettings);
    this.productPriceBreakdown = this.createProductPriceBreakdownService();
    this.filterConfigsService = this.createFilterConfigsService(initialData.appSettings.widgetSettings);

    this.filtersService = this.createFiltersService(currencyRateResponse);
    this.categoriesService = this.initializeCategoriesService(initialData);

    let currentCategory: ICategory;
    if (this.siteStore.experiments.enabled(Experiments.EnableStorefrontSubCategories)) {
      currentCategory = this.categoriesService.getProps().currentCategory;
    } else {
      currentCategory = initialData.catalog.category;
    }

    if (!currentCategory) {
      this.updateComponent({
        ...this.getEmptyStatePropsToInject(),
      });
      return null;
    }

    this.setCurrentCategory(currentCategory);

    const sorting: ISortingOption = this.getSortFromQueryParam();
    if (sorting) {
      this.sortService.setSelectedSort(sorting.id);
      this.productsService.updateSort(sorting);
    } else {
      this.sortService.setDefaultSortBySettings(this.styles.gallery_sortingDefaultOption.value);
      this.productsService.updateSort(this.sortService.getSelectedSort());
    }

    let filterModels: FilterModel[] = [];
    if (this.shouldShowFilters()) {
      const serverFilterModels = await this.filtersService.fetchFilters();
      filterModels = this.updateFiltersFromQueryParams(serverFilterModels);
      this.productsService.updateFilters(this.filtersService.getFiltersDTO());
    }

    if (this.loadMoreType() === LoadMoreType.PAGINATION && this.currentPage) {
      products = await this.loadProductsByPage(this.currentPage);
    } else {
      products = await this.getFilterProducts();
    }

    this.setProductItemSlotsProps(products);

    this.renderCategorySEOTags();

    this.updateComponent({
      ...this.getPropsToInject(products, filterModels),
      isFirstPage: this.currentPage <= 1,
    });

    if (!this.siteStore.isSSR()) {
      this.productsService.storeNavigation(this.siteStore.siteApis.currentPage.id);
    }
  }

  private initializeCategoriesService(initialData: GetCategoryInitialDataQuery) {
    const {
      category,
      allProductsCategoryId,
      categories: {list: allCategories},
    } = initialData.catalog;

    const categoriesService = new CategoriesService({
      currentCategory: category,
      allCategories,
      allProductsCategoryId,
      siteStore: this.siteStore,
      updateComponent: this.updateComponent,
    });

    categoriesService.updateVisibleCategories({
      categoryListConfig: initialData.appSettings.widgetSettings.CATEGORY_LIST_CONFIG as IFilterConfigDTO,
      shouldUseCategoryListConfig: this.styles.gallery_categoryListStrategy === CategoryListStrategy.MANUALLY,
    });

    return categoriesService;
  }

  private getInitialDataOptions(limit: number): Omit<IGetInitialData, 'withOptions' | 'withPriceRange'> {
    let initialDataOptions = {
      externalId: this.config.externalId,
      compId: this.compId,
      filters: null,
      limit,
      offset: 0,
      mainCollectionId: this.mainCollectionId,
    };

    if (this.veloInputs?.collectionId) {
      initialDataOptions = {...initialDataOptions, mainCollectionId: this.veloInputs.collectionId};
    } else if (this.veloInputs?.productIds) {
      initialDataOptions = {
        ...initialDataOptions,
        mainCollectionId: this.productsService.allProductsCategoryId,
        filters: {
          term: {
            field: 'id',
            op: 'IN',
            values: this.veloInputs.productIds,
          },
        },
      };
    }
    return initialDataOptions;
  }

  public onAppLoaded(): void {
    /* istanbul ignore else */
    if (this.isFedopsReport) {
      this.isFedopsReport = false;

      /* istanbul ignore next: debug */
      if (
        this.siteStore.experiments.enabled('specs.stores.ReportBiForEmptyGallery') &&
        !this.productsService.products.length
      ) {
        this.siteStore.webBiLogger.report(exposureEventForTests({testName: `empty-gallery-${this.type}`}));
      }

      this.siteStore.webBiLogger.report(viewGallerySf(this.getViewGallerySfParams()));
    }
  }

  private getViewCategoriesSfParams() {
    return {
      isCategoryPage: this.isCategoryPage,
      hasHeroImage: this.currentCategory?.media?.url && this.styles.gallery_showCategoryHeaderImage,
      hasHeroDescription: this.currentCategory?.description && this.styles.gallery_showCategoryHeaderDescription,
      hasProductCounter: this.styles.gallery_showProductsCounter,
      hasBreadcrumbs: this.styles.gallery_showCategoriesBreadcrumbs,
      hasCategoryTree: this.styles.gallery_showCategories,
    };
  }

  private getViewGallerySfParams() {
    const {galleryShowFilters, gallery_showAppliedFilters, galleryShowSort, gallery_productDropdownListWidth} =
      this.styles;

    const commonParams = super.commonViewGallerySfParams;
    const commonSettingsParams = super.commonViewGallerySfSettingsParams;

    let res = {
      ...commonParams,
      componentType: this.biType,
      filterType:
        (galleryShowFilters &&
          this.filtersService
            ?.getFilterModels()
            .map((m) => m.filterType)
            .join(',')) ||
        '',
      hasAppliedFilters: gallery_showAppliedFilters,
      hasOptions: this.shouldShowProductOptions,
      hasSorting: galleryShowSort,
      numOfColumns: this.isAutoGrid() ? undefined : this.galleryColumns,
      productsLogic: this.productsLogic,
      settings: JSON.stringify({
        ...commonSettingsParams,
        productDropdownListWidth: gallery_productDropdownListWidth,
      }),
    };
    if (this.isCategoryPage) {
      res = {...res, ...this.getViewCategoriesSfParams()};
    }
    return res;
  }

  private get productsLogic() {
    return this.mainCollectionId !== this.productsService.allProductsCategoryId ? 'collection' : 'All products';
  }

  private readonly shouldShowClearFilters = () => {
    if (this.siteStore.isMobile()) {
      return true;
    }
    const hasSelectedFilters = !!this.filtersService.getSelectedFilterTypes().length;

    if (this.isCategoryPage) {
      const appliedFiltersShown = this.styles.gallery_showAppliedFilters;
      return hasSelectedFilters && !appliedFiltersShown;
    }

    return hasSelectedFilters;
  };

  protected getEmptyStatePropsToInject() {
    return {
      isCategoryVisible: this.isCategoryVisible,
      useCategories: this.useCategories,
      isCategoryPage: this.isCategoryPage,
      currentCategory: this.currentCategory,
      isEditorMode: this.siteStore.isEditorMode(),
      textsMap: this.getTextsMap(),
      onAppLoaded: this.onAppLoaded.bind(this),
      isFirstPage: true,
      productsRequestInProgress: false,
      isHorizontalLayout: this.isHorizontalLayout(),
      isInteractive: this.siteStore.isInteractive(),
      isLiveSiteMode: this.siteStore.isSiteMode(),
      isLoaded: true,
      experiments: this.getExperimentsToInject(),
      shouldShowMobile: this.siteStore.isMobile(),
      isPreviewMode: this.siteStore.isPreviewMode(),
      isRTL: this.siteStore.isRTL(),
      cssBaseUrl: this.siteStore.baseUrls.galleryBaseUrl,
      fitToContentHeight: true, // thunderbolt prop that sets height: auto on the widget instead of fixed height
      imagePosition: this.imagePosition(),
    };
  }

  protected getPropsToInject(products: IProduct[], filterModels: FilterModel[] | []): IPropsInjectedByViewerScript {
    return {
      ...this.getComputedProps(products),
      ...this.sentryErrorBoundaryProps,
      ...super.getCommonPropsToInject(),
      isCategoryVisible: this.isCategoryVisible,
      isEditorMode: this.siteStore.isEditorMode(),
      fitToContentHeight: true, // thunderbolt prop that sets height: auto on the widget instead of fixed height
      addedToCartStatus: this.addedToCartStatus,
      allowFreeProducts: this.addToCartService.allowFreeProducts,
      applyFilteredProductsOnMobile: this.applyFilteredProductsOnMobile.bind(this),
      clearFilters: this.clearFilters.bind(this),
      cssBaseUrl: this.siteStore.baseUrls.galleryBaseUrl,
      linkForAllPages: this.getLinksForAllPages(),
      nextPrevLinks: this.nextPrevLinks(),
      totalPages: this.totalPages,
      maxProductsPerPage: this.maxProductsPerPage,
      filterModels,
      filterProducts: this.onFilterChange.bind(this),
      handleAddToCart: this.handleAddToCart.bind(this),
      sendSortClickBiEvent: this.sendSortClickBiEvent.bind(this),
      handlePagination: this.handlePagination.bind(this),
      handleProductItemClick: this.handleProductItemClick.bind(this),
      reportProductItemClick: this.reportProductItemClick.bind(this),
      handleCategoryClick: this.handleCategoryClick.bind(this),
      handleCategoryBreadcrumbsClick: this.handleCategoryBreadcrumbsClick.bind(this),
      handleCategoryClampClick: this.handleCategoryClampClick.bind(this),
      hasMoreProductsToLoad: this.productsService.hasMoreProductsToLoad(),
      hasSelectedFilters: this.hasSelectedFilters(),
      imagePosition: this.imagePosition(),
      isAutoGrid: this.isAutoGrid(),
      isCategoryPage: this.isCategoryPage,
      isFirstPage: true,
      isHorizontalLayout: this.isHorizontalLayout(),
      isInteractive: this.siteStore.isInteractive(),
      isLiveSiteMode: this.siteStore.isSiteMode(),
      isLoaded: true,
      shouldShowMobile: this.siteStore.isMobile(),
      isPreviewMode: this.siteStore.isPreviewMode(),
      isRTL: this.siteStore.isRTL(),
      isSSR: this.siteStore.isSSR(),
      isOptionsRevealEnabled: this.getIsOptionsRevealEnabled(),
      loadMoreProducts: this.loadMoreProducts.bind(this),
      loadMoreType: this.loadMoreType(),
      mainCollectionId: this.mainCollectionId,
      numberOfSelectedFilterTypes: this.getNumberOfSelectedFilterTypes(),
      onAppLoaded: this.onAppLoaded.bind(this),
      openQuickView: this.openQuickView.bind(this),
      paginationMode: this.getPaginationMode(),
      selectedSort: this.sortService.getSelectedSort(),
      shouldAlternateImagePosition: this.shouldAlternateImagePosition(),
      shouldShowAddToCartSuccessAnimation: this.getAddToCartAction() === AddToCartActionOption.NONE,
      shouldShowClearFilters: this.shouldShowClearFilters(),
      shouldShowMobileFiltersModal: false,
      shouldShowSort: this.shouldShowSort(),
      shouldShowProductOptions: this.shouldShowProductOptions,
      showShowLightEmptyState: this.productsService.hideGallery,
      shouldUseAutoGridProductsCount: this.shouldUseAutoGridProductsCount,
      sortProducts: this.onSortChange.bind(this),
      sortingOptions: this.sortService.updateVisibleSortingOptions(this.styles),
      mobileFiltersPanelState: this.mobileFiltersPanelState(),
      textsMap: this.getTextsMap(),
      toggleFiltersModalVisibility: this.toggleFiltersModalVisibility.bind(this),
      totalProducts: this.productsService.totalCount,
      updateAddToCartStatus: this.updateAddToCartStatus.bind(this),
      usingExtendedGridGalleryStyles: this.usingExtendedGridGalleryWidget,
      experiments: this.getExperimentsToInject(),
      handleProductsOptionsChange: this.handleOptionSelectionsChange.bind(this),
      productsRequestInProgress: false,
      currentCategory: this.currentCategory,
      breadcrumbsHistory: this.breadcrumbsItems,
      taxonomicalBreadcrumbs: this.taxonomicalBreadcrumbsItems,
      allProductsCategoryId: this.productsService.allProductsCategoryId,
      categories: this.categories,
      useCategories: this.useCategories,
      useFiltersAnnouncer: this.filterAnnouncerService.getProps(),
    };
  }

  private get useCategories(): CategoriesData {
    if (this.siteStore.experiments.enabled(Experiments.EnableStorefrontSubCategories) && this.categoriesService) {
      return this.categoriesService.getProps();
    }

    return {} as CategoriesData;
  }

  private getExperimentsToInject() {
    return {
      shouldRenderGalleryProductItemCarouselHover: this.siteStore.experiments.enabled(
        Experiments.GalleryProductItemCarouselHover
      ),
      isAllowGalleryProductRoundCornersInViewer: this.siteStore.experiments.enabled(
        Experiments.AllowGalleryProductRoundCornersInViewer
      ),
      editableGridTemplateRepeatOption: this.siteStore.experiments.enabled(
        Experiments.EditableGridTemplateRepeatOption
      ),
      shouldUseCommonDiscountPricingMethods: this.siteStore.experiments.enabled(
        Experiments.ShouldUseCommonDiscountPricingMethods
      ),
      shouldResetQuantityUponSelectionChange: this.siteStore.experiments.enabled(
        Experiments.ShouldResetQuantityUponSelectionChange
      ),
      shouldRenderSlotsInGallery: this.siteStore.experiments.enabled(Experiments.RenderSlotsInGallery),
      shouldRemoveModifiersSelectionIdsFromUserSelections: this.siteStore.experiments.enabled(
        Experiments.ShouldRemoveModifiersSelectionIdsFromUserSelections
      ),
      shouldUseGalleryProductItemsLazyLoadingForV3: this.siteStore.experiments.enabled(
        Experiments.GalleryProductItemsLazyLoadingForV3
      ),
      shouldShowLazyLoadingLoader: this.siteStore.experiments.enabled(Experiments.GalleryShowLazyLoadingLoader),
    };
  }

  private getTextsMap(): ITextsMap {
    return {
      addToCartContactSeller: this.translations['gallery.contactSeller.button'],
      addToCartOutOfStock:
        this.multilingualService.get('gallery_oosButtonText') || this.translations['gallery.outOfStock.button'],
      addToCartSuccessSR: this.translations['gallery.sr.addToCartSuccess'],
      allCollectionsFilterButtonText: this.translations['filter.CATEGORY_ALL'],
      clearFiltersButtonText: this.translations['filter.CLEAN_ALL'],
      digitalProductBadgeAriaLabelText: this.translations['sr.digitalProduct'],
      galleryAddToCartButtonText:
        this.multilingualService.get('gallery_addToCartText') || this.translations['gallery.addToCart.button'],
      galleryAddToCartPreOrderButtonText:
        this.multilingualService.get('gallery_preOrderText') || this.translations['gallery.preOrder.button'],
      galleryRegionSR: this.translations['sr.region.GALLERY'],
      filtersSubmitButtonText: this.translations['gallery.mobile.filters.buttonApply'],
      filtersTitleText: this.multilingualService.get('FILTERS_MAIN_TITLE') || this.translations['filter.MAIN_TITLE'],
      filtersAriaLabel: this.translations['gallery.mobile.filters.buttonClose'],
      loadMoreButtonText: this.multilingualService.get('LOAD_MORE_BUTTON') || this.translations.LOAD_MORE_BUTTON,
      loadPreviousButtonText:
        this.multilingualService.get('gallery_loadPreviousText') || this.translations['gallery.loadPrevious.button'],
      mobileFiltersButtonText: this.translations['gallery.mobile.filters.title.button'],
      mobileFiltersAndSortingText:
        this.multilingualService.get('gallery_filtersAndSortMobileButtonText') || this.combinedFiltersAndSortingTitle(),
      sortByText: this.translations['gallery.sortBy.label.defaultText'],
      noProductsFilteredMessageText: this.translations.NO_PRODUCTS_FILTERED_MESSAGE_MAIN,
      noProductsMessageText: this.translations.NO_PRODUCTS_MESSAGE_MAIN,
      sortOptionNewestText: this.translations['sort.NEWEST'],
      sortOptionLowPriceText: this.translations['sort.PRICE_LOW'],
      sortOptionHighPriceText: this.translations['sort.PRICE_HIGH'],
      sortOptionNameAZText: this.translations['sort.NAME_AZ'],
      sortOptionNameZAText: this.translations['sort.NAME_ZA'],
      sortMostRelevantText: this.translations['gallery.sortingOptions.bestMatch.option'],
      sortTitleText: this.multilingualService.get('SORTING_MAIN_TITLE') || this.translations.SORT_BY,
      sortRecommendedText: this.translations['gallery.sortingOptions.recommended.option'],
      productOutOfStockText:
        this.multilingualService.get('gallery_oosButtonText') || this.translations.OUT_OF_STOCK_LABEL,
      productPriceBeforeDiscountSR: this.translations['sr.PRODUCT_PRICE_BEFORE_DISCOUNT'],
      productPriceAfterDiscountSR: this.translations['sr.PRODUCT_PRICE_AFTER_DISCOUNT'],
      productPriceWhenThereIsNoDiscountSR: this.translations['sr.PRODUCT_PRICE_WHEN_THERE_IS_NO_DISCOUNT'],
      quickViewButtonText: this.translations.QUICK_VIEW,
      quantityAddSR: this.translations['gallery.sr.addQty'],
      quantityChooseAmountSR: this.translations['gallery.sr.chooseQty'],
      quantityRemoveSR: this.translations['gallery.sr.removeQty'],
      quantityInputSR: this.translations['gallery.sr.quantity'],
      announceFiltersUpdate: this.translations['sr.ANNOUNCE_FOUND_ITEMS_ON_FILTERS_UPDATE'],
      quantityMaximumAmountSR: this.translations['gallery.exceedsQuantity.error'],
      quantityTotalSR: this.translations['gallery.sr.totalQty'],
      quantityMinimumAmountSR: this.translations['gallery.minimumQuantity.error'],
      arrowPrevious: this.translations['gallery.sr.carousel.previous.label'],
      carouselContainerLabel: this.translations['gallery.sr.carousel.container.label'],
      arrowNext: this.translations['gallery.sr.carousel.next.label'],
      priceRangeText: this.translations['gallery.price.from.label'],
      pricePerUnitSR: this.translations['gallery.sr.units.basePrice.label'],
      measurementUnits: this.getMeasurementUnitsTranslation(),
      priceRangeMaxSR: this.translations['gallery.sr.filters.priceRange.max'],
      priceRangeMinSR: this.translations['gallery.sr.filters.priceRange.min'],
      productsCounterPlural: this.translations['gallery.numberOfProducts.label.plural'],
      productsCounterSingular: this.translations['gallery.numberOfProducts.label.singular'],
      productsCounterZero: this.translations['gallery.numberOfProducts.label.none'],
      sortLabel:
        this.multilingualService.get('SORTING_MAIN_TITLE') || this.translations['gallery.sortBy.label.defaultText'],
      categoryTreeTitle:
        this.multilingualService.get('categoryTreeTitle') || this.translations['gallery.categories.title.defaultText'],
      categoryHeaderReadMoreLink: this.translations['gallery.description.readMore.link'],
      categoryHeaderReadLessLink: this.translations['gallery.description.readLess.link'],
      emptyCategoryTitle: this.translations['gallery.emptyState.emptyCategory.title'],
      emptyCategoryBody: this.translations['gallery.emptyState.emptyCategory.body'],
      noFilterResultsTitle: this.translations['gallery.emptyState.noFilterResults.title'],
      noFilterResultsBody: this.translations['gallery.emptyState.noFilterResults.body'],
      noFilterResultsButton: this.translations['gallery.emptyState.noFilterResults.button'],
      emptyCategoryEditorTitle: this.translations['gallery.editor.emptyState.title'],
      emptyCategoryEditorSubTitle: this.translations['gallery.editor.emptyState.subtitle'],
      emptyCategoryPageEditorTitle:
        this.translations['gallery.categoryPage.editor.noVisibleCategories.emptyState.title'],
      emptyCategoryPageEditorSubtitle:
        this.translations['gallery.categoryPage.editor.noVisibleCategories.emptyState.body'],
      allProducts: this.translations['gallery.collection.allProducts'],
      categoryListShopAllButton: this.translations['gallery.categoryMenu.shopAll.text'],
      categoryListShowMore: this.translations['gallery.categoryMenu.showMore.text'],
      categoryListShowLess: this.translations['gallery.categoryMenu.showLess.text'],
      appliedFiltersClearAllButton: this.translations['gallery.appliedFilters.clearAll.button'],
      appliedFiltersPriceTag: this.translations['gallery.appliedFilters.priceRange.tag'],
      appliedFiltersContainerSR: this.translations['gallery.sr.appliedFilters.container'],
      appliedFilterClearSR: this.translations['gallery.sr.appliedFilters.clearFilter.button'],
      colorSwatchNumChoicesLeftLabel: this.translations['gallery.colorSwatch.numChoicesLeft.label'],
      colorSwatchNumMoreOptionsAriaLabel: this.translations['gallery.colorSwatch.numMoreOptions.ariaLabel'],
    };
  }

  private getLinksForAllPages(): string[] {
    const numberOfPages = this.totalPages < 1000 ? this.totalPages : 1000;

    return _.times(numberOfPages, (i) => this.queryParamsService.getUrlWithCustomPageParamForSeo(i + 1));
  }

  private nextPrevLinksMapped(): {prevUrl: string; nextUrl: string} {
    const res = {prevUrl: '', nextUrl: ''};
    const prevIndex = this.currentPage - 1;
    const nextIndex = this.currentPage + 1;

    if (prevIndex > 0) {
      res.prevUrl = this.queryParamsService.getUrlWithCustomPageParamForSeo(prevIndex);
    }

    if (nextIndex <= this.totalPages) {
      res.nextUrl = this.queryParamsService.getUrlWithCustomPageParamForSeo(nextIndex);
    }

    return res;
  }

  private nextPrevLinks(): string[] {
    const {prevUrl, nextUrl} = this.nextPrevLinksMapped();
    return [prevUrl, nextUrl].filter((url) => url);
  }

  private getMeasurementUnitsTranslation() {
    return _.reduce(
      unitsTranslations,
      (result, types, unit) => {
        result[unit] = result[unit] || {};
        _.each(types, (translationKey, type) => {
          result[unit][type] = this.translations[translationKey];
        });
        return result;
      },
      {}
    );
  }

  private generateProductsManifest(products: IProduct[]): ProductsManifest {
    return products.reduce((acc: ProductsManifest, product) => {
      acc[product.id] = {
        url: this.getProductPageUrl(product.urlPart),
        addToCartState: this.addToCartService.getButtonState({
          price: actualPrice(product),
          inStock: product.isInStock,
          isPreOrderState: isPreOrder(product),
        }),
        productItemsState: this.productItemsService.calculateProductItemsState(product),
      };
      return acc;
    }, {});
  }

  private getProductPageUrl(slug) {
    return this.isUrlWithOverrides
      ? this.customUrlApi.buildProductPageUrl({slug})
      : `${this.productPageSectionUrl}/${slug}`;
  }

  private onFilterChange(filterId: number, selectionValue: IFilterSelectionValue) {
    const filterModel = this.filtersService.getFilterModel(filterId);
    this.filtersService.updateActiveFilterOption(filterModel, selectionValue);
    this.updateComponent({
      filterModels: this.filtersService.getFilterModels(),
      hasSelectedFilters: this.hasSelectedFilters(),
      shouldShowClearFilters: this.shouldShowClearFilters(),
      numberOfSelectedFilterTypes: this.getNumberOfSelectedFilterTypes(),
    });
    this.queryParamsService.updateFiltersQueryParams({
      filterModel,
      mainCollectionId: this.mainCollectionId,
      //remove when merging when specs.stores.PreventGalleryFullRefreshOnUrlChange
      forceUpdateFromUrl: true,
    });

    this.siteStore.webBiLogger.report(
      clickToChangeGalleryFiltersSf({
        filterType: this.filtersService.getFilterModel(filterId).filterType,
      })
    );
  }

  private async filterProductsBySideEffect() {
    this.fedopsLogger.interactionStarted(FedopsInteraction.Filter);
    this.panoramaClient.transaction(FedopsInteraction.Filter).start();

    this.updateFiltersFromQueryParams(this.filtersService.getFilterModels(), true);
    this.productsService.updateFilters(this.filtersService.getFiltersDTO());
    this.updateComponent({
      filterModels: this.filtersService.getFilterModels(),
      hasSelectedFilters: this.hasSelectedFilters(),
      shouldShowClearFilters: this.shouldShowClearFilters(),
      numberOfSelectedFilterTypes: this.getNumberOfSelectedFilterTypes(),
    });
    this.filterAnnouncerService.setShouldAnnounceFilters(true);
    await this.updateComponentWithFilteredProducts();
    this.fedopsLogger.interactionEnded(FedopsInteraction.Filter);
    this.panoramaClient.transaction(FedopsInteraction.Filter).finish();
  }

  private setCurrentCategory(category: ICategory) {
    this.currentCategory = category;
    this.mainCollectionId = this.currentCategory.id;
    this.productsService.setMainCollection(this.currentCategory.id);
    this.filtersService.setMainCollectionId(this.currentCategory.id);
    this.setBreadcrumbsHistory();
    this.setTaxonomicalBreadcrumbs();
  }

  private applyFilteredProductsOnMobile() {
    this.siteStore.webBiLogger.report(
      galleryClickApplyFilter({
        filterTypes: this.filtersService.getSelectedFilterTypes().toString(),
      })
    );

    this.updateComponent({
      numberOfSelectedFilterTypes: this.filtersService.getSelectedFilterOptionsCount(),
      shouldShowMobileFiltersModal: false,
    });
  }

  private async clearFilters() {
    this.filtersService.resetFilters();

    this.queryParamsService.clearAllFiltersQueryParams(this.queryParamsFilterKeys);

    this.siteStore.webBiLogger.report(galleryClickClearAllFilters({}));

    await this.updateComponentWithFilteredProducts();
  }

  private toggleFiltersModalVisibility(show: boolean) {
    if (show) {
      this.siteStore.webBiLogger.report(galleryClickFilter({}));
    }

    this.updateComponent({
      shouldShowMobileFiltersModal: show,
      filterModels: this.filtersService.getFilterModels(),
      hasSelectedFilters: this.hasSelectedFilters(),
    });
  }

  private async getFilterProducts() {
    const filterDTO = this.filtersService.getFiltersDTO();
    const shouldSpecificCollectionQuery = this.filtersService.shouldSpecificCollectionQuery(this.mainCollectionId);
    return this.productsService.filterProducts({
      filters: filterDTO,
      collectionIds: this.filtersService.getCollectionIdsFilterDTO(),
      shouldSpecificCollectionQuery,
      limit: this.productsLimit,
    });
  }

  private async updateComponentWithFilteredProducts() {
    const products = await this.getFilterProducts();
    if (this.externalDataSourceConfig?.facetedFilters) {
      await this.fetchFilters();
      this.updateFiltersFromQueryParams(this.filtersService.getFilterModels(), true);
      this.productsService.updateFilters(this.filtersService.getFiltersDTO());
    }
    this.setProductItemSlotsProps(products);
    this.updateComponent({
      ...this.getComputedProps(products),
      filterModels: this.filtersService.getFilterModels(),
      hasMoreProductsToLoad: this.productsService.hasMoreProductsToLoad(),
      hasSelectedFilters: this.hasSelectedFilters(),
      shouldShowClearFilters: this.shouldShowClearFilters(),
      shouldShowSort: this.shouldShowSort(),
      currentCategory: this.currentCategory,
      breadcrumbsHistory: this.breadcrumbsItems,
      taxonomicalBreadcrumbs: this.taxonomicalBreadcrumbsItems,
      numberOfSelectedFilterTypes: this.getNumberOfSelectedFilterTypes(),
      useFiltersAnnouncer: this.filterAnnouncerService.getProps(),
    });
  }

  private hasSelectedFilters(): boolean {
    return this.filtersService.hasSelectedFilters();
  }

  private readonly loadMoreType = (): LoadMoreType => {
    return this.styles.gallery_loadMoreProductsType;
  };

  public updateAppOnQueryParamsChange({
    isPageUpdated,
    isFiltersUpdated,
    isSortUpdated,
  }: {
    isPageUpdated?: boolean;
    isFiltersUpdated?: boolean;
    isSortUpdated?: boolean;
  }) {
    const lastPage = this.currentPage;
    this.currentPage = this.queryParamsService.getPageQueryParam();
    if (isPageUpdated && (!isFiltersUpdated || !isSortUpdated)) {
      if (this.loadMoreType() === LoadMoreType.PAGINATION) {
        void this.handlePaginationBySideEffect();
      } else if (this.currentPage > lastPage) {
        void this.loadMoreProductsBySideEffect();
      }
    }
    if (isSortUpdated) {
      void this.sortProductsBySideEffect();
    }
    if (isFiltersUpdated) {
      void this.filterProductsBySideEffect();
    }
  }

  private async loadMoreProductsBySideEffect() {
    const visibleProducts = this.productsService.products.length;
    const fedopsInteraction =
      this.loadMoreType() === LoadMoreType.BUTTON ? FedopsInteraction.LoadMore : FedopsInteraction.InfiniteScroll;
    this.fedopsLogger.interactionStarted(fedopsInteraction);
    this.panoramaClient.transaction(fedopsInteraction).start();
    this.productsService.setProductsPerPage(this.maxProductsPerPage);

    const shouldSpecificCollectionQuery = this.filtersService.shouldSpecificCollectionQuery(this.mainCollectionId);
    const products = await this.productsService.loadMoreProducts({
      visibleProducts,
      shouldSpecificCollectionQuery,
    });
    if (products === null) {
      this.updateComponent({
        hasMoreProductsToLoad: false,
        productsRequestInProgress: false,
        isFirstPage: false,
      });
      return;
    }

    let focusedProductIndex = visibleProducts;
    const disableFocusProduct = this.siteStore.experiments.enabled(Experiments.DisableFocusProductOnInfiniteScroll);
    if (disableFocusProduct && this.loadMoreType() === LoadMoreType.INFINITE) {
      focusedProductIndex = -1;
    }

    this.setProductItemSlotsProps(products);

    this.updateComponent({
      ...this.getComputedProps(products),
      focusedProductIndex,
      linkForAllPages: this.getLinksForAllPages(),
      nextPrevLinks: this.nextPrevLinks(),
      hasMoreProductsToLoad: this.productsService.hasMoreProductsToLoad(),
      productsRequestInProgress: false,
    });

    this.fedopsLogger.interactionEnded(fedopsInteraction);
    this.panoramaClient.transaction(fedopsInteraction).finish();
  }

  private loadMoreProducts() {
    this.updateComponent({
      productsRequestInProgress: true,
    });
    this.siteStore.webBiLogger.report(
      clickLoadMoreInGallerySf({
        ...this.getBICollection(),
        type: this.loadMoreType() === LoadMoreType.BUTTON ? BiEventParam.LoadMore : BiEventParam.InfiniteScroll,
      })
    );
    this.queryParamsService.updatePageQueryParam(this.currentPage + 1);
  }

  private readonly hasOptions = (product: IProduct) => !!product.options.length;

  private readonly getAddToCartAction = () => this.styles.gallery_addToCartAction;

  private readonly updateAddToCartStatus = (productId: string, status: AddToCartActionStatus) => {
    this.addedToCartStatus[productId] = status;
    this.updateComponent({addedToCartStatus: this.addedToCartStatus});
  };

  private handleAddToCart({productId, index, quantity}: {productId: string; index: number; quantity: number}) {
    this.updateAddToCartStatus(productId, AddToCartActionStatus.IN_PROGRESS);

    this.productsService
      .addToCart({
        productId,
        index,
        compId: this.compId,
        externalId: this.config.externalId,
        quantity,
        addToCartAction: this.getAddToCartAction(),
        isRemoveModifiersSelectionIdsFromUserSelections: this.shouldRemoveModifiersSelectionIdsFromUserSelections,
      })
      .then(() => {
        this.updateAddToCartStatus(productId, AddToCartActionStatus.SUCCESSFUL);
      });
  }

  private readonly getSortFromQueryParam = (): ISortingOption | undefined => {
    if (this.siteStore.location.query.sort) {
      return this.sortService.getSort(this.queryParamsService.getQueryParam('sort'));
    }
  };

  private readonly getProductsLimitByPageFromQueryParam = (productPerPage: number): number => {
    return parseInt(this.queryParamsService.getQueryParam(DefaultQueryParamKeys.Page), 10) * productPerPage;
  };

  public get queryParamsFilterKeys(): string[] {
    return this.filtersService.getFilterModels()?.map((f) => f.title);
  }

  private readonly updateFiltersFromQueryParams = (filterModels: FilterModel[], resetWhenEmpty = false) => {
    const queryParamsFilters = this.queryParamsService.getFiltersQueryParams(filterModels);
    if (queryParamsFilters) {
      this.filtersService.updateActiveFilterOptionsByQueryParams(
        queryParamsFilters,
        this.siteStore.experiments.enabled(Experiments.UseNewFiltersQueryParamDecoder)
      );
    } else if (resetWhenEmpty) {
      this.filtersService.resetFilters();
    }

    return this.filtersService.getFilterModels();
  };

  private onSortChange(sorting: ISorting) {
    this.fedopsLogger.interactionStarted(FedopsInteraction.Sort);
    this.panoramaClient.transaction(FedopsInteraction.Sort).start();
    this.sortService.setSelectedSort(sorting.id);
    this.queryParamsService.updateSortQueryParams(sorting);
  }

  private async sortProductsBySideEffect() {
    const selectedSort = this.getSortFromQueryParam() ?? this.sortService.getDefaultSortingOption();
    this.productsService.updateSort(selectedSort);
    this.siteStore.webBiLogger.report(
      sortGallerySf({sortDir: selectedSort?.direction, method: selectedSort?.id.split('_')[0]})
    );
    const shouldSpecificCollectionQuery = this.filtersService.shouldSpecificCollectionQuery(this.mainCollectionId);
    const products = await this.productsService.sortProducts(
      selectedSort?.field ? selectedSort : null,
      shouldSpecificCollectionQuery
    );

    this.setProductItemSlotsProps(products);
    this.updateComponent({
      ...this.getComputedProps(products),
      hasMoreProductsToLoad: this.productsService.hasMoreProductsToLoad(),
      isFirstPage: false,
      selectedSort,
    });

    this.fedopsLogger.interactionEnded(FedopsInteraction.Sort);
    this.panoramaClient.transaction(FedopsInteraction.Sort).finish();
  }

  public refreshGallery() {
    const queryParamsActiveFilters = this.queryParamsService.getFiltersQueryParams(
      this.filtersService.getFilterModels()
    );

    if (queryParamsActiveFilters?.length > 0) {
      this.clearGalleryQueryParams();
    } else {
      this.updateAppOnQueryParamsChange({isFiltersUpdated: true});
    }
  }

  public clearGalleryQueryParams() {
    this.queryParamsService.clearAllQueryParams(this.queryParamsFilterKeys.map(encodeURIComponent));
  }

  private isHorizontalLayout() {
    const {gallery_imagePlacement, 'mobile:gallery_imagePlacement': mobileImagePlacement} = this.styles;
    const imagePlacement = this.getStyleParamByDevice(mobileImagePlacement, gallery_imagePlacement).value;

    return imagePlacement === ImagePlacements.HORIZONTAL;
  }

  private shouldAlternateImagePosition() {
    const {
      gallery_alternateImagePosition: desktopAlternateImagePosition,
      'mobile:gallery_alternateImagePosition': mobileAlternateImagePosition,
    } = this.styles;

    return this.getStyleParamByDevice(mobileAlternateImagePosition, desktopAlternateImagePosition);
  }

  private imagePosition(): ImagePositions {
    const {gallery_imagePosition: desktopImagePosition, 'mobile:gallery_imagePosition': mobileImagePosition} =
      this.styles;

    return this.getStyleParamByDevice<ImagePositions>(
      mobileImagePosition?.value as ImagePositions,
      desktopImagePosition?.value as ImagePositions
    );
  }

  protected get imageMode(): ImageModeId {
    const {gallery_imageMode: desktopImageMode, 'mobile:gallery_imageMode': mobileImageMode} = this.styles;

    return this.getStyleParamByDevice(mobileImageMode, desktopImageMode);
  }

  protected get imageRatio(): ImageRatioId {
    const {galleryImageRatio: desktopImageRatio, 'mobile:galleryImageRatio': mobileImageRatio} = this.styles;
    return this.getStyleParamByDevice(mobileImageRatio, desktopImageRatio);
  }

  private shouldShowSort() {
    const {
      galleryShowSort,
      gallerySortNewest,
      gallerySortPriceAsc,
      gallerySortPriceDes,
      gallerySortNameAsc,
      gallerySortNameDes,
      gallerySortRecommended,
    } = this.styles;
    const isAnySortActive =
      gallerySortNewest ||
      gallerySortPriceAsc ||
      gallerySortPriceDes ||
      gallerySortNameAsc ||
      gallerySortNameDes ||
      (this.isCategoryPage && gallerySortRecommended);

    return (
      galleryShowSort &&
      isAnySortActive &&
      ((this.isCategoryPage && this.siteStore.isDesktop()) || this.productsService.products?.length > 0)
    );
  }

  private openQuickView({
    productId,
    index,
    selectionIds,
    quantity,
  }: {
    productId: string;
    index: number;
    selectionIds?: number[];
    quantity?: number;
  }) {
    this.productsService.quickViewProduct(productId, index, {
      compId: this.compId,
      externalId: this.config.externalId,
      selectionIds,
      quantity,
    });
  }

  protected reportProductItemClick({productId, index}: {productId: string; index: number}) {
    const product = this.productsService.getProduct(productId);
    this.siteStore.webBiLogger.report(
      clickOnProductBoxSf({
        productId,
        hasRibbon: !!product.ribbon,
        hasOptions: this.hasOptions(product),
        index,
        productType: product.productType,
        galleryType: this.type,
        galleryProductsLogic: this.productsLogic,
      })
    );
    this.productsService.sendClickTrackEvent(product, index);
  }

  private handleProductItemClick({
    biData: {productId, index},
  }: {
    biData: {
      productId: string;
      index: number;
    };
  }) {
    const product = this.productsService.getProduct(productId);

    this.queryParamsService.updateScrollToProduct(product.urlPart);

    this.productsService.storeNavigation(this.siteStore.siteApis.currentPage.id);
    this.reportProductItemClick({productId, index});

    if (this.veloInputs?.onItemSelected) {
      this.veloInputs.onItemSelected.callBack(product.id, product, index);
      /* istanbul ignore next: WIP, having issues with spy on navigation. will be tested soon */
      if (this.veloInputs.onItemSelected.options.preventNavigation) {
        /* istanbul ignore next: WIP, having issues with spy on navigation. will be tested soon */
        return;
      }
    }

    this.siteStore.navigate(
      {
        sectionId: PageMap.PRODUCT,
        state: product.urlPart,
        queryParams: undefined,
      },
      true
    );
  }

  private get categories() {
    return this.categoriesService?.visibleCategories ?? [];
  }

  private fetchFilters(): Promise<FilterModel[]> {
    return this.filtersService.fetchFilters();
  }

  private shouldShowFilters(): boolean {
    const galleryShowFilters = this.styles.galleryShowFilters;
    return galleryShowFilters && this.filterConfigsService.shouldShowFilters();
  }

  private getNumberOfSelectedFilterTypes() {
    return this.filtersService.getSelectedFilterOptionsCount();
  }

  private mobileFiltersPanelState(): MobileFiltersPanelState {
    const hasFilters = this.filtersService?.getFilterModels()?.length > 0;
    const shouldShowFilters = this.styles.galleryShowFilters && hasFilters;
    const shouldShowSort = this.shouldShowSort();

    if (shouldShowFilters && !shouldShowSort) {
      return MobileFiltersPanelState.FILTERS_ONLY;
    }

    if (!shouldShowFilters && shouldShowSort) {
      return MobileFiltersPanelState.SORT_ONLY;
    }

    if (shouldShowFilters && shouldShowSort) {
      return MobileFiltersPanelState.FILTERS_AND_SORT;
    }

    return MobileFiltersPanelState.NONE;
  }

  private combinedFiltersAndSortingTitle() {
    switch (this.mobileFiltersPanelState()) {
      case MobileFiltersPanelState.FILTERS_ONLY:
        return this.translations['gallery.mobile.filter.defaultText'];
      case MobileFiltersPanelState.SORT_ONLY:
        return this.translations['gallery.mobile.sort.defaultText'];
      case MobileFiltersPanelState.FILTERS_AND_SORT:
      default:
        return this.translations['gallery.mobile.filterAndSort.defaultText'];
    }
  }

  private sendSortClickBiEvent() {
    this.siteStore.webBiLogger.report(galleryClickSortBy({}));
  }

  private updatePublicData(newPublicData: IGalleryControllerConfig['publicData']) {
    this.config.publicData = newPublicData;
  }

  public async updateState(
    config: IGalleryControllerConfig,
    newPublicData: IGalleryControllerConfig['publicData'] & {appSettings?: any}
  ): Promise<void> {
    this.config = config;
    const previousShouldShowProductOptions = this.shouldShowProductOptions;
    const oldStyles = this.styles;
    this.updateStyles();

    this.updatePublicData(newPublicData);

    if (newPublicData.appSettings) {
      this.multilingualService.setWidgetSettings(newPublicData.appSettings);
      if (newPublicData.appSettings.FILTERS) {
        this.filterConfigsService.setFilterConfigDTOs(newPublicData.appSettings.FILTERS);
        this.filtersService.deleteFilterModels();
      }
    }

    if (!this.shouldShowFilters()) {
      this.filtersService.deleteFilterModels();
      this.updateComponent({filterModels: []});
    } else if (this.filtersService.getFilterModels().length === 0) {
      const filterModels = await this.filtersService.fetchFilters();
      this.updateComponent({filterModels});
    }

    const shouldUpdateCategoryList =
      this.styles.gallery_categoryListStrategy !== oldStyles.gallery_categoryListStrategy ||
      !!newPublicData?.appSettings?.CATEGORY_LIST_CONFIG;

    if (shouldUpdateCategoryList) {
      this.categoriesService.updateVisibleCategories({
        categoryListConfig: newPublicData?.appSettings?.CATEGORY_LIST_CONFIG,
        shouldUseCategoryListConfig: this.styles.gallery_categoryListStrategy === CategoryListStrategy.MANUALLY,
      });
    }

    let nextProps: Partial<IPropsInjectedByViewerScript> = {
      isAutoGrid: this.isAutoGrid(),
      loadMoreType: this.loadMoreType(),
      shouldShowAddToCartSuccessAnimation: this.getAddToCartAction() === AddToCartActionOption.NONE,
      shouldShowSort: this.shouldShowSort(),
      mobileFiltersPanelState: this.mobileFiltersPanelState(),
      textsMap: this.getTextsMap(),
      shouldShowProductOptions: this.shouldShowProductOptions,
      isOptionsRevealEnabled: this.getIsOptionsRevealEnabled(),
      isHorizontalLayout: this.isHorizontalLayout(),
      shouldAlternateImagePosition: this.shouldAlternateImagePosition(),
      imagePosition: this.imagePosition(),
      shouldShowImageCarousel: this.shouldShowImageCarousel,
      maxProductsPerPage: this.maxProductsPerPage,
      totalPages: this.totalPages,
      shouldUseAutoGridProductsCount: this.shouldUseAutoGridProductsCount,
      sortingOptions: this.sortService.updateVisibleSortingOptions(this.styles),
      categories: this.categories,
      useCategories: this.useCategories,
    };

    const shouldUpdateWithOptions = previousShouldShowProductOptions !== this.shouldShowProductOptions;
    const isFixedGridProductsCountChanged =
      this.styles.gallery_fixedGridProductsCount !== oldStyles.gallery_fixedGridProductsCount;
    const isDefaultSortingChanged =
      this.styles.gallery_sortingDefaultOption.value !== oldStyles.gallery_sortingDefaultOption.value;
    const shouldFetchProducts =
      shouldUpdateWithOptions ||
      this.styles.gallery_productsCount !== oldStyles.gallery_productsCount ||
      isDefaultSortingChanged ||
      isFixedGridProductsCountChanged;

    this.productsService.setWithOptions(this.shouldShowProductOptions);

    if (shouldFetchProducts) {
      if (isFixedGridProductsCountChanged) {
        this.setGalleryRows(true);
        this.productsService.setProductsPerPage(this.maxProductsPerPage);
      }

      if (isDefaultSortingChanged) {
        nextProps.selectedSort = this.sortService.setDefaultSortBySettings(
          this.styles.gallery_sortingDefaultOption.value
        );

        this.productsService.updateSort(nextProps.selectedSort);
      }

      const shouldSpecificCollectionQuery = this.filtersService.shouldSpecificCollectionQuery(this.mainCollectionId);
      nextProps.products = await this.productsService.loadProducts({
        from: 0,
        to: this.productsService.getProductPerPage(),
        shouldSpecificCollectionQuery,
      });
      nextProps = {
        ...nextProps,
        maxProductsPerPage: this.maxProductsPerPage,
        productsManifest: this.generateProductsManifest(nextProps.products),
        totalPages: this.totalPages,
      };
    }

    nextProps.productsPriceRangeMap = this.productsService.productPriceRangeMap;
    this.updateComponent({...nextProps, ...this.getCommonPropsToUpdate()});
  }

  private getBICollection() {
    const collectionId = this.productsService.getMainCollectionId();
    return collectionId === this.productsService.allProductsCategoryId ? {} : {categoryId: collectionId};
  }

  private async loadProductsByPage(page: number) {
    const from = this.maxProductsPerPage * (page - 1);
    const to = from + this.maxProductsPerPage;
    const shouldSpecificCollectionQuery = this.filtersService.shouldSpecificCollectionQuery(this.mainCollectionId);
    return this.productsService.loadProducts({
      from,
      to,
      shouldSpecificCollectionQuery,
    });
  }

  private handleCategoryClick({
    destinationLink,
    destinationCategoryId,
  }: {
    destinationLink: string;
    destinationCategoryId: string;
  }) {
    return this.siteStore.webBiLogger.report(
      categoryPageCategoryTreeClicked({
        link: destinationLink,
        destinationCategoryId,
        originCategoryId: this.currentCategory.id,
      })
    );
  }

  private handleCategoryClampClick(isClamped: boolean) {
    return isClamped
      ? this.siteStore.webBiLogger.report(categoryPageHeroSectionReadLessClicked({}))
      : this.siteStore.webBiLogger.report(categoryPageHeroSectionReadMoreClicked({}));
  }

  private handleCategoryBreadcrumbsClick({item, originCategoryId}: {item: BreadcrumbsItem; originCategoryId: string}) {
    return this.siteStore.webBiLogger.report(
      categoryPageBreadcrumbClicked({
        link: item.href,
        originCategoryId,
        destinationCategoryId: item.id.toString(),
      })
    );
  }

  private async handlePaginationBySideEffect() {
    this.fedopsLogger.interactionStarted(FedopsInteraction.Pagination);
    this.panoramaClient.transaction(FedopsInteraction.Pagination).start();
    const products = await this.loadProductsByPage(this.currentPage);
    if (this.siteStore.experiments.enabled(Experiments.GalleryAddMissingAddProductImpressionEvent)) {
      this.productsService.sendTrackEvent(0);
    }
    this.setProductItemSlotsProps(products);
    this.updateComponent(this.getComputedProps(products));
    this.fedopsLogger.interactionEnded(FedopsInteraction.Pagination);
  }

  private handlePagination(page: number) {
    this.queryParamsService.updatePageQueryParam(page);
    this.siteStore.webBiLogger.report(
      clickLoadMoreInGallerySf({...this.getBICollection(), type: BiEventParam.Pagination})
    );
  }

  private getComputedProps(products: IProduct[]) {
    this.productsManifest = this.generateProductsManifest(products);
    return {
      currentPage: this.currentPage,
      scrollToProduct: this.scrollToProduct,
      isFirstPage: false,
      productsManifest: this.productsManifest,
      products,
      totalProducts: this.productsService.totalCount,
      totalPages: this.totalPages,
      productsVariantInfoMap: this.productsVariantInfoMap,
      productsPriceRangeMap: this.productsService.productPriceRangeMap,
      shouldShowImageCarousel: this.shouldShowImageCarousel,
      isGalleryRowsAndColsWereModified: this.isGalleryRowsAndColsWereModified,
    };
  }

  private readonly isResponsive = () => {
    return this.styles.responsive === true;
  };

  private readonly isAutoGrid = () => {
    if (this.siteStore.isMobile() && !this.isResponsive()) {
      return false;
    }

    return this.styles.gallery_gridType === GridType.AUTO;
  };

  private get shouldUseAutoGridProductsCount() {
    return this.styles.gallery_gridType === GridType.AUTO;
  }

  private getPaginationMode(): PaginationTypeName {
    const paginationFormat = this.styles.gallery_paginationFormat;

    return this.siteStore.isMobile() || paginationFormat === PaginationType.COMPACT ? 'compact' : 'pages';
  }

  private setTaxonomicalBreadcrumbs() {
    const breadcrumbs = this.currentCategory?.breadcrumbs?.map((breadcrumb) => {
      return {
        id: breadcrumb.id,
        value: breadcrumb.name,
        href: `${this.siteStore.location.baseUrl}/${RouterPrefix.CATEGORY}/${breadcrumb.slug}`,
      };
    });

    this.taxonomicalBreadcrumbsItems = [
      {
        id: 'home',
        value: this.translations['gallery.breadcrumbs.firstItem'],
        href: this.siteStore.location.baseUrl,
      },
      ...(breadcrumbs || []),
      {
        id: this.currentCategory.id,
        value: this.currentCategory.name,
        href: '',
      },
    ];
  }

  private readonly setBreadcrumbsHistory = () => {
    this.breadcrumbsItems = [
      {id: 'home', href: this.homepageUrl, value: this.translations['gallery.breadcrumbs.firstItem']},
      {
        id: this.currentCategory.id,
        href: '',
        value:
          this.currentCategory.name === 'All Products' ? this.getTextsMap().allProducts : this.currentCategory.name,
      },
    ];
  };

  protected get stylesParams() {
    switch (this.type) {
      case StoresWidgetID.CATEGORY_PAGE:
        return categoryStylesParams;
      case StoresWidgetID.SEARCH_MODAL_GALLERY:
        return searchModalPageStylesParams;
      case StoresWidgetID.SEARCH_RESULTS_GALLERY:
        return searchResultsPageStylesParams;
      default:
        return gridGalleryStylesParams;
    }
  }

  private get shouldQueryCurrencyRate() {
    return (
      this.siteStore.experiments.enabled(Experiments.PriceFilterClientTicksCalculation) &&
      this.isUsingAlternativeCurrency
    );
  }
}
